import Router from 'next/router';
import qs from '@/lib/qs';

const updateRouteQuery = (newData, reset = false) => {
  const [pathname, search] = Router.asPath.split('?');
  const query = qs.parse(search);

  Router.replace(
    {
      pathname,
      search: qs.stringify({
        ...(!reset && { ...query }),
        ...newData,
      }),
    },
    undefined,
    { shallow: true },
  );
};

export default updateRouteQuery;
