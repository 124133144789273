import styled from '@emotion/styled';
import { themeGet, space } from 'styled-system';
import { Flex, Icon } from '@qga/roo-ui/components';

const Select = styled.select`
  outline: none;
  border: none;
  cursor: pointer;
  appearance: none;
  height: 100%;
  width: 100%;
  color: ${themeGet('colors.greys.charcoal')};
  background: transparent;
  text-overflow: ellipsis;
  ${space}
`;

const StyledIcon = styled(Icon)`
  pointer-events: none;
  position: absolute;
  right: 12px;
`;

const SearchSelect = ({ ...props }) => (
  <Flex height="100%" alignItems="center" position="relative">
    <Select
      px={4}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
    <StyledIcon name="expandMore" size={24} />
  </Flex>
);

SearchSelect.defaultProps = {
  ...Select.defaultProps,
};

SearchSelect.propTypes = {
  ...Select.propTypes,
};

export default SearchSelect;
