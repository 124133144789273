import type { UI } from '@/v2/types';

import { useEffect } from 'react';
import { Box, Container } from '@qga/roo-ui/components';

import { Layout } from '@/v2/components/layout/Layout';
import { Page } from '@/v2/components/layout/Page';
import DestinationLinks from '@/v2/components/ui/SiteFooter/components/DestinationLinks';
import usePageViewEvent from '@/v2/hooks/usePageViewEvent';

import { dataLayer } from './utils/dataLayer';

// Legacy components
import ImportantInformation from '../../../../components/ImportantInformation';
import Divider from '../../../../components/Divider';
import GlobalCVPBanner from '../../../../components/GlobalCVPBanner';
import WebChat from '../../../../components/WebChat';

import getFeatureFlag from '../../../../utils/getFeatureFlag';

import HeroBanner from '../../../../components/HomePage/components/HeroBanner';
import Subheader from '../../../../components/HomePage/components/Subheader';
import ExclusiveOffersLink from '../../../../components/HomePage/components/ExclusiveOffersLink';
import ExclusiveOffers from '../../../../components/HomePage/components/ExclusiveOffers';
import Campaigns from '../../../../components/HomePage/components/Campaigns';
import PopularDestinations from '../../../../components/HomePage/components/PopularDestinations';
import HolidayTypes from '../../../../components/HomePage/components/HolidayTypes';

// Note: Layout called here temporarily with props
// - needs to go to _app when v2 is complete

interface Props {
  app: UI.Settings;
  homePage: UI.Home;
  navigation: Array<UI.Navigation>;
}

const HomeView = ({ homePage, ...rest }: Props) => {
  useEffect(() => {
    dataLayer();
  }, []);

  usePageViewEvent({ type: 'home' });

  return (
    <Layout {...rest}>
      <Page data-testid="HOME_PAGE">
        <HeroBanner />
        <Box py="4">
          <Container px="0">
            <GlobalCVPBanner />
          </Container>
        </Box>
        {getFeatureFlag('LUXURY_CARDS_ENABLED') && <Subheader />}
        <Box bg="greys.porcelain">
          {getFeatureFlag('LUXURY_CARDS_ENABLED') && (
            <>
              <Container>
                <ExclusiveOffers />
                <ExclusiveOffersLink />
              </Container>
            </>
          )}
          <Container>
            <Campaigns />
          </Container>
        </Box>
        <Box bg="white">
          <Container>
            <PopularDestinations />
            <Divider my="10" />
            <HolidayTypes />
          </Container>
        </Box>
      </Page>
      <Box bg="white" pt="9" pb="15">
        <Container>
          <ImportantInformation />
        </Container>
      </Box>
      <DestinationLinks />
      <WebChat />
    </Layout>
  );
};

export default HomeView;
