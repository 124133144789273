import React from 'react';
import { Box } from '@qga/roo-ui/components';
import useHomePage from '../../hooks/useHomePage';
import CardListing from '../../../ExclusiveOffersPage/components/CardListing';

const ExclusiveOffers = React.memo(() => {
  const { exclusiveOffers } = useHomePage();

  return (
    <Box pb="5">
      <CardListing tiles={exclusiveOffers} />
    </Box>
  );
});

export default ExclusiveOffers;
