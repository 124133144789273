import React from 'react';
import { Box } from '@qga/roo-ui/components';
import formatMoney from '@/utils/formatMoney';
import CampaignDealSummary from '../CampaignDealSummary';
import CampaignDealDetail from '../CampaignDealDetail';

interface Props {
  campaign: any;
}

const CampaignDeal = ({ campaign }: Props) => {
  const {
    nights,
    origin,
    price,
    travellers,
    displayTravellerTypes,
    cheapestBrand,
  } = campaign.leadInOffer;

  const isStaysOnly = cheapestBrand === `qantas-hotels`;

  const moneyAmount = isStaysOnly
    ? price?.total?.amount
    : price?.perTraveller?.amount;

  const campaignPrice =
    moneyAmount &&
    formatMoney(moneyAmount, {
      wholeDollars: true,
    });

  return campaignPrice ? (
    <>
      <Box mt="6" alignItems="center">
        <CampaignDealSummary isStaysOnly={isStaysOnly} nights={nights} />
      </Box>
      <CampaignDealDetail
        price={campaignPrice}
        origin={origin}
        travellers={travellers}
        displayTravellerTypes={displayTravellerTypes}
      />
    </>
  ) : null;
};

export default CampaignDeal;
