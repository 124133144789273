import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@qga/roo-ui/components';
import SearchSelect from '@/components/SearchSelect';
import OriginOptions from '@/components/OriginOptions';
import useOrigins from '@/hooks/useOrigins';

const OriginSelect = ({ codes, value, onChange, placeholder, ...props }) => {
  const origins = useOrigins(codes);
  return (
    <SearchSelect
      data-testid="ORIGIN_SELECT"
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      value={value ? value.code : ''}
      onChange={(event) => {
        const origin = origins.find((o) => o.code === event.target.value);

        onChange(origin);
      }}
    >
      <OriginOptions originCodes={codes} placeholder={placeholder} />
    </SearchSelect>
  );
};

OriginSelect.defaultProps = {
  value: undefined,
  placeholder: 'Please select',
};

OriginSelect.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default OriginSelect;
