const getDateErrorText = (
  searched: boolean,
  error: { [key: string]: string },
) => {
  if (searched && (!!error.departureDate || !!error.returnDate)) {
    return 'Please select travel dates';
  }
  return '';
};

export default getDateErrorText;
