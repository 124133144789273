import PropTypes from 'prop-types';
import React from 'react';
import Destination from '../../propTypes/Destination';
import DestinationContext from './DestinationContext';

const DestinationProvider = ({ value, children }) => (
  <DestinationContext.Provider value={value}>
    {children}
  </DestinationContext.Provider>
);

DestinationProvider.propTypes = {
  value: PropTypes.shape(Destination).isRequired,
  children: PropTypes.node.isRequired,
};

export default DestinationProvider;
