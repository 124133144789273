import styled from '@emotion/styled';
import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import { Heading, Paragraph } from '@qga/roo-ui/components';
import withProps from '@/components/withProps';
import PointsPerDollar from '@/components/PointsPerDollar/PointsPerDollar';
import Link from '@/components/Link';
import SquareHeroBannerLayout from '@/components/SquareHeroBannerLayout';
import useHomePage from '../../../../hooks/useHomePage';

const CallToAction = withProps({
  variant: 'primary',
})(styled(Link.Button)`
  color: white;
  border-color: white;
  margin-top: 1rem;
`);

const Content = React.memo(() => {
  const { heroBanner } = useHomePage();

  return (
    <SquareHeroBannerLayout.Content
      gutter={['0', null, 'default']}
      width={[null, null, '600px']}
    >
      <BlockContent
        blocks={heroBanner.text}
        serializers={{
          types: {
            block: ({ node, children }) =>
              node.style === 'title' ? (
                <Heading.h1 fontWeight="normal">{children}</Heading.h1>
              ) : (
                <Paragraph color="greys.steel">{children}</Paragraph>
              ),
          },
          marks: {
            pointsPerDollar: PointsPerDollar,
            callToAction: withProps(({ mark }) => ({
              href: mark.href,
              external: mark.external,
            }))(CallToAction),
          },
        }}
      />
    </SquareHeroBannerLayout.Content>
  );
});

export default Content;
