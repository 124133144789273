import useSWR from 'swr';
import { DEFAULT_SEARCH_PARAMS } from '@/constants';
import useDestination from '@/hooks/useDestination';
import useSearchQuery from '@/hooks/useSearchQuery';
import qs from '@/lib/qs';
import validateSearchQuery from '@/utils/validateSearchQuery';
import usePropertyContent from '../usePropertyContent';

/**
 * @deprecated - use new v2 Typesript version
 */
const usePackageOffers = () => {
  const propertyContent = usePropertyContent();
  const destination = useDestination();
  const { searchQuery } = useSearchQuery();

  const isValidSearchQuery =
    propertyContent &&
    validateSearchQuery(destination?.searchConfig, searchQuery);

  const searchParams = {
    propertyId: propertyContent?.id,
    destinationCode: destination?.searchConfig.destinationCode,
    ...DEFAULT_SEARCH_PARAMS,
    ...searchQuery,
  };

  const { data, error } = useSWR(() => {
    if (!isValidSearchQuery) {
      return null;
    }

    return qs.stringifyUrl({
      url: '/package/offers',
      query: searchParams,
    });
  });

  const loading = !data && !error && isValidSearchQuery;

  return {
    searchParams,
    searchQuery: isValidSearchQuery ? searchQuery : null,
    error,
    loading,
    availability: data && data.availability,
    flightOptions: data && data.flightOptions,
    packageOffers: data && data.packageOffers,
    holidaysPointsTiers: data && data.qffPointTiers,
  };
};

export default usePackageOffers;
