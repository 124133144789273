import { getTravellersCount } from '@experiences-ui/shared/utils';
import { BOOKABLE_PERIOD, MAX_STAY, MAX_TRAVELLERS_COUNT } from '@/constants';
import { addDays, startOfDay } from '@/lib/date';

const validateSearchQuery = (searchConfig, searchQuery) => {
  if (!searchConfig || !searchQuery) return false;
  const { originCodes } = searchConfig;
  const { departureDate, returnDate, originCode, adults, children, infants } =
    searchQuery;

  if (!departureDate || !returnDate || !originCode || !adults) {
    return false;
  }

  const d = startOfDay(new Date());
  const dd = new Date(departureDate);
  const rd = new Date(returnDate);

  const isBookableDepartureDate = dd >= addDays(d, BOOKABLE_PERIOD.min);
  const isBookableReturnDate = rd <= addDays(d, BOOKABLE_PERIOD.max);
  const isValidDateRangeOrder = rd > dd;
  const isLessThenMaxStayDateRange = rd < addDays(dd, MAX_STAY);

  const isValidOriginCode = !!originCodes.includes(originCode);

  const isValidTravellers =
    getTravellersCount({
      adults,
      children,
      infants,
    }) <= MAX_TRAVELLERS_COUNT;

  return (
    isBookableDepartureDate &&
    isBookableReturnDate &&
    isValidDateRangeOrder &&
    isLessThenMaxStayDateRange &&
    isValidOriginCode &&
    isValidTravellers
  );
};

export default validateSearchQuery;
