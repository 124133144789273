import enrichTiersWithInfinity from '@/utils/enrichTiersWithInfinity';
import { useAppSettings } from '@/components/AppSettingsProvider';
import { useApp } from '@/v2/contexts/app';
import usePackageOffers from '@/components/PackagePage/hooks/usePackageOffers';

const useTiers = () => {
  const appSettings = useAppSettings();
  const app = useApp();
  const offer = usePackageOffers();

  const qffPointTiers =
    offer?.holidaysPointsTiers ||
    app?.qffPointTiers?.holidaysPointsTiers ||
    appSettings?.qffPointTiers?.holidaysPointsTiers;

  const { tiers } = enrichTiersWithInfinity(qffPointTiers);
  return tiers;
};

export default useTiers;
