import React from 'react';
import { Flex } from '@qga/roo-ui/components';
import useHomePage from '../../hooks/useHomePage';
import BannerImage from './components/BannerImage';
import Content from './components/Content';
import SearchForm from './components/SearchForm';
import Terms from './components/Terms';

const HeroBanner = React.memo(() => {
  const { search } = useHomePage();

  return (
    <Flex
      data-testid="HERO_BANNER"
      position="relative"
      pb={[null, null, 10]}
      gap={[null, null, 10]}
      flexDirection="column"
    >
      <BannerImage />
      <Content />
      <SearchForm searchDestinations={search} isHomeSearch />
      <Terms />
    </Flex>
  );
});

export default HeroBanner;
