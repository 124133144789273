import React from 'react';
import { Box } from '@qga/roo-ui/components';
import getTravellersDescription from '@/utils/getTravellersDescription';
import { Origin } from '@/v2/services/sanity/types/api/response';
import { Travellers } from '@/v2/services/sanity/types/api/parsedResponse';

interface Props {
  price: string;
  travellers: Travellers;
  displayTravellerTypes: boolean;
  origin?: Origin;
  dataTestId?: string;
}

const CampaignDealDetail = React.memo(
  ({
    price,
    origin,
    travellers,
    displayTravellerTypes,
    dataTestId = 'CAMPAIGN-DEAL-DETAIL',
  }: Props) => (
    <>
      <Box
        fontSize={['xl', null, '2xl']}
        fontWeight="bold"
        data-testid={`${dataTestId}-PRICE`}
      >
        {price}*
      </Box>
      <Box
        fontSize="sm"
        fontWeight="bold"
        data-testid={`${dataTestId}-TRAVELLERS`}
      >
        {`Per person for ${getTravellersDescription(
          travellers,
          displayTravellerTypes,
        )}`}
      </Box>
      {origin && (
        <Box
          fontSize="sm"
          color="greys.steel"
          data-testid={`${dataTestId}-DEPARTING-FROM`}
        >
          {`Departing ${origin.name}`}
        </Box>
      )}
    </>
  ),
);

export default CampaignDealDetail;
