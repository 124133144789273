import Link from '@/components/Link';
import { Flex } from '@qga/roo-ui/components';

const ExclusiveOffersLink = () => (
  <Flex
    data-testid="exclusive-offers-button-container"
    justifyContent="center"
    mt={[0, 0, 3]}
    mb={[3, 3, 6]}
  >
    <Link.Button variant="primary" href="/deals">
      View All Luxury Holidays
    </Link.Button>
  </Flex>
);

export default ExclusiveOffersLink;
