import { Icon } from '@qga/roo-ui/components';
import React from 'react';
import withProps from '@/components/withProps';
import Summary from '@/components/Summary';

const Prefix = withProps({
  name: 'flightRight',
})(Icon);

interface Props {
  isStaysOnly: boolean;
  nights?: number;
}

const summaryText = ({ nights, isStaysOnly }: Props) => {
  if (isStaysOnly) {
    if (!nights) {
      return 'HOTEL + INCLUSIONS';
    } else {
      return `${nights} NIGHT STAY + INCLUSIONS FROM`;
    }
  } else {
    if (!nights) {
      return 'FLIGHTS + HOTEL';
    } else {
      return `FLIGHTS + ${nights} NIGHT STAY FROM`;
    }
  }
};

const CampaignDealSummary = React.memo(({ nights = 0, isStaysOnly }: Props) => (
  <Summary
    dataTestId="CAMPAIGN-DEAL-SUMMARY"
    text={summaryText({ nights, isStaysOnly })}
    prefix={!isStaysOnly ? <Prefix /> : null}
  />
));

export default CampaignDealSummary;
