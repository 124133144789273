import { useRouter } from 'next/router';
import { useMemo } from 'react';
import getSearchQuery from '@/utils/getSearchQuery';
import updateRouteQuery from '@/utils/updateRouteQuery';

const useSearchQuery = () => {
  const router = useRouter();
  const searchQuery = useMemo(() => getSearchQuery(router.query), [router]);

  return {
    searchQuery,
    setSearchQuery: updateRouteQuery,
  };
};

export default useSearchQuery;
