import PropTypes from 'prop-types';
import React from 'react';
import PropertyContent from '@/propTypes/PropertyContent';
import PropertyContentContext from './PropertyContentContext';

const PropertyContentProvider = ({ value, children }) => (
  <PropertyContentContext.Provider value={value}>
    {children}
  </PropertyContentContext.Provider>
);

PropertyContentProvider.propTypes = {
  value: PropTypes.shape(PropertyContent).isRequired,
  children: PropTypes.node.isRequired,
};

export default PropertyContentProvider;
