import React from 'react';
import { Box } from '@qga/roo-ui/components';
import Destinations from '@/components/Destinations';
import useHomePage from '../../hooks/useHomePage';

const ExploreOurHolidayDestinations = React.memo(() => {
  const { destinations } = useHomePage();

  return (
    <Box my="10" data-testid="DESTINATIONS">
      <Destinations
        heading="Explore our holiday destinations"
        destinations={destinations}
      />
    </Box>
  );
});

export default ExploreOurHolidayDestinations;
