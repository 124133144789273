import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@qga/roo-ui/components';
import Image from '@/shared/components/Image';
import urlFor from '../../../../../../../../../libs/shared/sanity/image/urlFor';
import useHomePage from '../../../../hooks/useHomePage';

const StyledImage = styled(Image)`
  object-position: top;
`;

const BannerImage = React.memo(() => {
  const { heroBanner } = useHomePage();

  return (
    <Box
      position={[null, null, 'absolute']}
      height={['190px', null, '100%']}
      left="0"
      right="0"
      top="0"
      bottom="0"
    >
      <StyledImage
        data-testid="BACKGROUND_IMAGE"
        src={urlFor(heroBanner.image).width(1600).url()}
        alt="Qantas Holidays"
        width="100%"
        height="100%"
        fit="cover"
        ssr
      />
    </Box>
  );
});

export default BannerImage;
