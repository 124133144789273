import React from 'react';
import { Box } from '@qga/roo-ui/components';
import CampaignOption from './components/CampaignOption';
import useHomePage from '../../hooks/useHomePage';

const Campaigns = React.memo(() => {
  const { campaigns } = useHomePage();

  return (
    <Box pt={[6, 6, 9]} pb={[4, 4, 16]}>
      {campaigns.map((campaign) => (
        <Box key={campaign.id} my="4">
          <CampaignOption campaign={campaign} />
        </Box>
      ))}
    </Box>
  );
});

export default Campaigns;
