import React from 'react';
import { Box } from '@qga/roo-ui/components';
import Destinations from '@/components/Destinations';
import useHomePage from '../../hooks/useHomePage';

const HolidayTypes = React.memo(() => {
  const { themes } = useHomePage();

  return (
    <Box mt="10" pb="10">
      <Destinations heading="Holiday types" destinations={themes} />
    </Box>
  );
});

export default HolidayTypes;
