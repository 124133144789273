import PropTypes from 'prop-types';
import React from 'react';
import { groupBy } from 'ramda';
import { MAJOR_CITIES } from '@/constants';
import useOrigins from '@/hooks/useOrigins';

const OriginOptions = ({ originCodes, placeholder }) => {
  const origins = useOrigins(originCodes);

  const { captains = [], others = [] } = groupBy(
    (o) => (MAJOR_CITIES.includes(o) ? 'captains' : 'others'),
    origins,
  );

  return (
    <>
      {placeholder && (
        <option data-testid="PLACEHOLDER" disabled value="">
          {placeholder}
        </option>
      )}
      {captains.map(({ name, code }) => (
        <option data-testid="CAPTAIN" key={code} value={code}>
          {name}
        </option>
      ))}
      {!!captains.length && !!others.length && (
        <option data-testid="DIVIDER" disabled>
          -
        </option>
      )}
      {others.map(({ name, code }) => (
        <option data-testid="OTHER" key={code} value={code}>
          {name}
        </option>
      ))}
    </>
  );
};

OriginOptions.defaultProps = {
  placeholder: undefined,
  originCodes: [],
};

OriginOptions.propTypes = {
  placeholder: PropTypes.string,
  originCodes: PropTypes.arrayOf(PropTypes.string),
};

export default OriginOptions;
