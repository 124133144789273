import PropTypes from 'prop-types';
import React from 'react';
import { BackgroundImage, Box, Flex, Paragraph } from '@qga/roo-ui/components';
import Link from '@/components/Link';
import Campaign from '@/propTypes/Campaign';
import urlFor from '../../../../../../../../../libs/shared/sanity/image/urlFor';
import { EXCLUSIVE_OFFERS_PAGE_CAMPAIGN_SLUG } from '@/constants';
import CampaignDeal from '../CampaignDeal';

const CampaignOption = ({ campaign }) => {
  if (campaign.slug === EXCLUSIVE_OFFERS_PAGE_CAMPAIGN_SLUG) return null;
  const href = `/deals/${campaign.slug}`;

  return (
    <>
      <Box>
        <Flex
          bg="white"
          flexDirection={['column', null, 'row']}
          minHeight="200px"
        >
          <Box
            height={['160px', null, 'initial']}
            width={[null, null, '50%']}
            position="relative"
          >
            <BackgroundImage
              height="100%"
              data-testid="CAMPAIGN_IMAGE"
              src={urlFor(campaign.image).height(600).url()}
              role="img"
            />
            <Link.Cover data-testid="IMAGE_LINK" href={href} />
          </Box>
          <Flex
            flexDirection={['column', null, 'row']}
            p={['4', null, '8']}
            flex="1"
          >
            <Flex flexDirection="column" flex="1">
              <Box fontSize="lg">
                <Link.Naked href={href}>
                  {campaign.dealBanner.headline}
                </Link.Naked>
              </Box>
              <Box mt="2" flex="1">
                {campaign.dealBanner.tagline}
              </Box>
              <CampaignDeal campaign={campaign} />
            </Flex>
            <Flex
              mt={['4', null, '0']}
              ml={[null, null, '4']}
              alignItems={[null, null, 'flex-end']}
            >
              <Link.Button
                width={['100%', null, 'auto']}
                href={href}
                outline
                variant="primary"
              >
                VIEW DEALS
              </Link.Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Paragraph mt="1" fontSize="sm" color="greys.steel">
        {campaign.dealBanner.termsConditions}
      </Paragraph>
    </>
  );
};

CampaignOption.propTypes = {
  campaign: PropTypes.shape(Campaign).isRequired,
};

export default CampaignOption;
