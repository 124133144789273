import React from 'react';
import { Box, Text, Container, Heading } from '@qga/roo-ui/components';

const Subheader = () => (
  <Box bg="greys.porcelain" py="6" data-testid="subheader">
    <Container>
      <Heading.h3>Discover our latest Luxury Holiday offers</Heading.h3>
      <Text fontSize="sm" color="greys.steel">
        Expertly curated luxury offers with VIP inclusions
      </Text>
    </Container>
  </Box>
);

export default Subheader;
