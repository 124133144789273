import styled from '@emotion/styled';
import React from 'react';
import { Box, Container } from '@qga/roo-ui/components';
import { background } from 'styled-system';
import useHomePage from '../../../../hooks/useHomePage';

const Wrapper = styled(Box)`
  ${background}
`;

const Terms = () => {
  const { heroBanner } = useHomePage();

  if (!heroBanner.termsConditions) {
    return null;
  }

  return (
    <Wrapper
      position={['relative', null, 'absolute']}
      color={[null, null, 'white']}
      pt="1"
      pb="3"
      right="0"
      bottom="0"
      left="0"
      background={[
        null,
        null,
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)',
      ]}
    >
      <Container>
        <Box fontSize="sm">{heroBanner.termsConditions}</Box>
      </Container>
    </Wrapper>
  );
};

export default Terms;
