import { isNil, pick, pipe, reject } from 'ramda';

const KEYS = [
  'originCode',
  'departureDate',
  'returnDate',
  'adults',
  'children',
  'infants',
  'offerId',
];

const getSearchQuery = (searchCriteria) => {
  if (!searchCriteria) {
    return undefined;
  }

  return pipe(pick(KEYS), reject(isNil))(searchCriteria);
};

export default getSearchQuery;
